// @ts-nocheck
import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"

const BlogPostHeroImage = ({
  blog,
  className = "hero-image",
  loading = "eager",
}) => {
  if (blog.hero)
    return (
      <GatsbyImage
        alt={blog?.meta?.title}
        image={getImage(blog.hero)}
        className={className}
        loading={loading}
      />
    )
  if (!blog?.meta?.herourl) return null
  const widths = [1792, 1440, 1024, 768, 480, 320]
  const srcSet = widths.map(width => {
    return (
      blog.meta.herourl.replace(".png", `-w${width}.webp`) +
      ` ${width}w`
    )
  })
  return (
    <img
      src={blog.meta.herourl.replace(".png", `-w${widths[0]}.webp`)}
      srcSet={srcSet.join(", ")}
      alt={blog?.meta?.title || ''}
      width={460}
      height={270}
      className={className + ' aspect-[1792/1024]'}
      loading="eager"
    />
  )
}

BlogPostHeroImage.propTypes = {
  blog: PropTypes.object.isRequired,
  className: PropTypes.string,
  loading: PropTypes.oneOf(["eager", "lazy"]),
}

export default BlogPostHeroImage
